import React from "react"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <SectionWrapper>
      <h1>Privacy Policy</h1>

      <strong>Privacy and Security</strong>

      <p>EHS Momentum LLC is committed to protecting your personal information. This statement explains our practices for this Web site.</p>

      <strong>What Personal Information We Gather</strong>

      <p>If interested, you can submit your name, email, phone number and a message to EHS Momentum.</p>

      <strong>Credit Cards</strong>

      <p>We accept credit card payments on this web site only through Stripe, our third party credit card system, and no credit card data is stored or managed by this website. If someone uses your credit card to make an unauthorized purchase, check with the institution that issued your card to find out what the exact liability is.</p>

      <strong>Email</strong>

      <p>If you send us an email, we may save it if we think we’ll need it to communicate with you. We never sell or share email addresses. Remember, email is not a secure way to send personal information because it is not encrypted. Please don’t send any personal information to us using email; use our secure forms instead.</p>

      <strong>Cookies</strong>

      <p>Our Web site stores information in a small text file, called a cookie, on your computer. We use what is called a “persistent cookie,” which is stored as a file on your computer and remains there when you close your Web browser. The cookie can only be read by the Web site that created it when you visit that site again.</p>

      <strong>Our Use of Cookies</strong>

      <p>EHS Momentum LLC stores a unique number (not any personal information) in the cookie that we place on your computer. We use this cookie to determine if you have visited the site before. The information we store in the cookie does not include data such as your name, company, email address, telephone number, state/province, country or the industry you work in.</p>

      <strong>Security Concerns</strong>

      <p>Once the cookie is saved on your computer, our Web site is the only site that can access and read the information that you provide. EHS Momentum LLC will not provide this information to any third parties or use it for sending unsolicited email.</p>

      <strong>Java and Javascript</strong>

      <p>Java is a programming language that allows different kinds of computers to talk to each other. We use Java technology to provide functionality such as navigation menus, forms and so on. You will still be able to use this Web site if you have Java or Javascript disabled, but some functionality may not be available.</p>

      <strong>Changes to this Statement</strong>

      <p>We may amend this privacy and security statement from time to time. If we make any substantial changes in the way we use your personal information, we will notify you by posting a prominent announcement on this Web site.</p>
    </SectionWrapper>
  </Layout>
)

export default PrivacyPolicy

const SectionWrapper = styled.div`
  margin: auto;
  max-width: ${props => props.theme.section.width};
  padding: 50px 40px 30px;

  strong {
    margin: ${props => props.theme.spacing.large} 0 ${props => props.theme.spacing.small};
  }

  p {
    margin: ${props => props.theme.spacing.small} 0 ${props => props.theme.spacing.large};
  }
`